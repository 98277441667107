<script setup>
import { computed, onBeforeUnmount, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import Doughnut from '@common/Doughnut'

const store = useStore()
store.dispatch('fetchStat', { part: 'plan' })
const stat = computed(() => store.state.stat)

/******************
 * Doughnut Maker *
 ******************/
const chart = reactive({
  creditUsed: 0,
  creditRemain: 0,
  activePercent: 0,
  inactivePercent: 0
})

const bars = reactive([])
watch(stat, (stat) => {
  // Init doughnut
  chart['creditUsed'] = stat['credit_use']
  chart['creditRemain'] = stat['credit_remain']
  const activePercent =
    stat['credit'] !== 0
      ? Math.round((stat['credit_remain'] / stat['credit']) * 100)
      : 0
  const inactivePercent =
    stat['credit'] !== 0
      ? Math.round((stat['credit_use'] / stat['credit']) * 100)
      : 0
  chart['activePercent'] = activePercent
  chart['inactivePercent'] = inactivePercent

  // Init Bars
  bars[1] = {
    id: 1,
    count: stat['credit_use'].toLocaleString(),
    width: inactivePercent + '%',
    color: '#777',
    title: 'اعتبار مصرف شده'
  }
  bars[0] = {
    id: 2,
    count: stat['credit_remain'].toLocaleString(),
    width: activePercent + '%',
    color: '#c69d4e',
    title: 'اعتبار فعال'
  }
})

onBeforeUnmount(() => {
  store.dispatch('clearStat')
})
</script>

<template>
  <div class="stat-wrapper">
    <section class="load-resolved" v-if="stat">
      <div class="chartTitle">
        <h3 class="chartTitle__text">مبلغ اعتبار صادر شده</h3>
        <h4 class="chartTitle__number chartTitle--rial">
          {{ stat && stat['credit'].toLocaleString() }}
        </h4>
      </div>
      <div class="cmp-items-wrapper">
        <div class="chart">
          <doughnut
            :dataset="[
              chart['creditRemain'] === 0 ? 0.001 : chart['creditRemain'],
              chart['creditUsed'] === 0 ? 0.001 : chart['creditUsed']
            ]"
            :colors="['#c69d4e', '#777']"
            :size="150"
            :thickness="15"
            style="position:relative; bottom: 5px;"
          />
          <div class="chart__legend">
            <div class="chart__row">
              <span class="chart__value value--gold"
                >{{ chart['activePercent'] }}%</span
              >
              <h5 class="chart__title">اعتبار فعال</h5>
            </div>
            <div class="chart__row">
              <span class="chart__value value--silver"
                >{{ chart['inactivePercent'] }}%</span
              >
              <h5 class="chart__title">اعتبار مصرف شده</h5>
            </div>
          </div>
        </div>
        <div class="chart chart--hist">
          <div class="hist-wrapper">
            <div class="chart-row" v-for="bar in bars" :key="bar.id">
              <div class="chart-bar-row">
                <div class="chart-bar">
                  <i
                    :style="{
                      backgroundColor: bar['color'],
                      width: bar['width'] === '0%' ? '3px' : bar['width']
                    }"
                  />
                </div>
              </div>
              <div class="chart-row-stat">
                <h6>{{ bar['count'] }}</h6>
                <h5>{{ bar['title'] }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-wrapper">
        <div>
          <h5>تعداد طرح ها</h5>
          <!-- remove 0% from stats -->
          <h6>{{ stat['plans'] - 1 }}</h6>
        </div>
        <hr />
        <div>
          <h5>تعداد طرح های فعال</h5>
          <h6>{{ stat['plans_active'] - 1 }}</h6>
        </div>
        <hr />
        <div>
          <h5>تعداد طرح های باطل شده</h5>
          <h6>{{ stat['plans_inactive'] }}</h6>
        </div>
      </div>
    </section>
    <section class="load-pending" v-else>
      <img src="@img/vipon-loading.svg" />
    </section>
  </div>
</template>
