<script setup>
import { computed, onBeforeUnmount, ref, watch, defineProps } from 'vue'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'
import PlanTransactionSingle from './subComponents/PlanTransactionSingle'

const props = defineProps(['id'])

const store = useStore()
const transactions = computed(() => store.state.plan.backupItems)
const pageStatus = computed(() => store.state.plan.pageStatus)
const isDark = computed(() => store.state.plan.isDark)
store.dispatch('plan/fetchItems', {
  part: 'transaction',
  backup: true,
  query: { plan: props.id }
})

// Intersection
const intersected = () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('plan/fetchItems', {
    part: 'transaction',
    backup: true,
    query: { plan: props.id }
  })
}

// Handle two loadings
const firstLoad = ref(true)
watch(pageStatus, (curr) => {
  if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
})

// Clean the room
onBeforeUnmount(() => store.dispatch('plan/clearBackupItems'))

// Import time functions
const { localizeDate, getHour } = dateConvertor()
</script>

<template>
  <div :class="['cmp-wrapper', isDark && 'cmp-wrapper--dark']">
    <navbar msg="تراکنش‌ ها" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <FailedMode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <i class="radius" />
        <PlanTransactionSingle
          v-for="trn in transactions"
          :key="trn['id']"
          :price="trn['price']"
          :receiver-name="trn['cashier']"
          :date="localizeDate(trn['created_at'])"
          :hour="getHour(trn['created_at'])"
          :is-dark="isDark"
        />
        <Observer @intersect="intersected" />
        <Loader v-if="pageStatus == 'loading' && !firstLoad" />
      </div>
    </section>
    <FixedFooter />
  </div>
</template>

<style lang="scss" scoped>
.radius {
  background-color: #e0e0e0;
  width: 100%;
  height: 1px;
}
// change in common css
.cmp-wrapper--dark {
  background: #181818;
}
.cmp-wrapper--dark .radius {
  background-color: rgba(#fff, 0.1);
}
</style>
