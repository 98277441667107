<script setup>
import { computed, reactive, ref, defineProps } from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { localizeDate } from '@util/Time'
import { disablePlan } from '@service/PlanService'
import PlanSingle from './subComponents/PlanSingle'

const props = defineProps({
  id: String
})
const store = useStore()
const modals = reactive({
  confirm: false
})
const plan = computed(() => store.state.plan.item)
const isDark = computed(() => store.state.plan.isDark)
const pageStatus = computed(() => store.state.plan.pageStatus)

// Initial api call
store.dispatch('plan/fetchItem', { part: 'plan', id: props.id })

// Handle disabling Plan
const btnLoading = ref(false)
const handleDisablePlan = async () => {
  btnLoading.value = true
  try {
    await disablePlan(props.id)
    // Reset plans of the sell
    store.dispatch('plan/setSellPlans', [])
    store.dispatch('addToast', {
      type: 'success',
      message: 'طرح اعتباری با موفقیت غیرفعال شد.'
    })
    store.dispatch('plan/fetchItem', { part: 'plan', id: props.id })
    setTimeout(() => router.push({ name: 'PlanHome' }), 2000)
  } catch (ex) {
    if (ex.response) {
      store.dispatch('addToast', {
        type: 'error',
        message: 'درخواست شما موفقیت‌آمیز نبود.'
      })
    }
  } finally {
    btnLoading.value = false
    modals['confirm'] = false
  }
}

// Enter transactions
const router = useRouter()
const enterTransactions = () => {
  if (plan.value && plan.value['sell_count'] === 0) {
    return store.dispatch('addToast', {
      type: 'error',
      message: 'تا کنون تراکنشی ثبت نشده است.'
    })
  }
  router.push({ name: 'PlanTransaction', params: { id: props.id } })
}

// handle retry
const retry = () => {
  store.dispatch('plan/fetchItem', { part: 'plan', id: props.id })
}

// Save some data before leaving
onBeforeRouteLeave((to, _, next) => {
  if (to.name === 'PlanTransaction') {
    next()
  } else {
    store.dispatch('plan/clearItem')
    next()
  }
})
</script>

<template>
  <div :class="['plans-wrapper', isDark && 'plan--dark']">
    <Navbar msg="طرح اعتباری" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <FailedMode @refresh="retry" v-else-if="pageStatus == 'failed'" />
    <section class="load-resolved" v-else>
      <PlanSingle
        :title="plan['name']"
        :is-dark="isDark"
        :percent="plan['const_percent']"
        :credit-inner="true"
      />
      <div :class="['table', isDark && 'table--dark']">
        <div class="table__row">
          <h4 class="table__cell cell--title">تعداد فروش</h4>
          <h5 class="table__cell cell--amount">
            {{ plan['sell_count'].toLocaleString() }}
          </h5>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h4 class="table__cell cell--title">مبلغ فروش</h4>
          <h5 class="table__cell cell--amount cell--rial">
            {{ plan['sell_sum'].toLocaleString() }}
          </h5>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h4 class="table__cell cell--title">اعتباردهی</h4>
          <h5 class="table__cell cell--amount cell--rial">
            {{ plan['credit'].toLocaleString() }}
          </h5>
        </div>
      </div>
      <div :class="['tab', isDark && 'tab--dark']">
        <a class="tab__part" @click="enterTransactions">
          <span class="tab__status">مشاهده تراکنش ها</span>
        </a>
      </div>
      <div
        class="credit-buttons"
        v-if="!isDark"
        @click="modals['confirm'] = true"
      >
        <button type="button" class="button">
          <span class="button__text">
            ابطال
          </span>
          <svg class="button__icon" viewBox="0 0 512 512" v-show="!btnLoading">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
        </button>
      </div>
      <div class="credit-buttons" v-if="isDark">
        <button class="button button--dark">
          <svg class="button__icon" viewBox="0 0 512 512" v-show="!btnLoading">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
          <span class="button__text">{{
            localizeDate(plan['cancelled_at'])
          }}</span>
        </button>
      </div>
    </section>
    <ModalConfirm
      :is-open="modals['confirm']"
      :is-loading="btnLoading"
      @modalconfirmclosed="modals['confirm'] = false"
      @modalconfirmcancelled="modals['confirm'] = false"
      @confirm="handleDisablePlan"
      text='آیا از "ابطال" این طرح اعتباری مطمئن هستید؟'
    />
  </div>
  <FixedFooter />
</template>

<style lang="scss" scoped>
$dark-bg-color-primary: #080808;
$dark-bg-color-secondary: #0e0e0e;

.plans-wrapper {
  background: #eee;
  padding: 60px 0 50px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
.plan--dark {
  background: $dark-bg-color-primary;
  i {
    background-color: #1c1c1c;
  }
}
.countdown {
  margin-top: 0;
  padding-top: 10px;
  width: 100%;
}

// change in common css
.credit-buttons {
  margin: 10px 0;
}
.button {
  background-color: #000;
  width: 60%;
  border-radius: 15px;
  .button__text {
    color: #fff;
  }
}
.button--dark {
  background-color: transparent;
  .button__text {
    color: #333;
  }
}
.tab {
  padding: 0 20px;
  justify-content: center;
  margin: 10px 0 5px;
}
.tab--dark {
  background-color: $dark-bg-color-primary;
}
.tab--dark .tab__part {
  background-color: #181818;
}
.tab__part {
  width: 100%;
}
.tab__status {
  font-size: 14px;
  color: #111;
}
.tab--dark .tab__status {
  color: #555;
}

.time-wrapper {
  margin: 60px 0 0;
  background-color: initial;
}
.offBtn__svg {
  top: -1px;
}
.offBtn__text {
  top: 1px;
}

// Table
.table {
  margin: 0;
  border: none;
}
.table__row {
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;
}

.cell--title {
  position: relative;
  top: 2px;
  font-size: 16px;
  margin: 0;
  // font-weight: bold;
  color: #333;
}

.cell--amount {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  color: #111;
  margin: 0;
  padding-top: 3px;
  font-weight: bold;
}

.cell--rial::after {
  color: #111;
  position: relative;
  content: 'R';
  font-size: 10px;
  right: -1px;
  top: -4px;
}

.table--dark .cell--amount {
  color: #333;
  &::after {
    color: #333;
  }
}
</style>
