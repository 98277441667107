<script setup>
import { reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { schemaMaker, validateForms, modalNumber } from '@valid/JoiValidation'
import { addPlan } from '@service/PlanService'

// Main items
const store = useStore()
const router = useRouter()
const PLAN_TABLE_PRICE = 500
const modals = reactive({
  percent: false
})
const items = [...Array.from({ length: 100 }, (_, i) => i + 1)]
const formItems = reactive({
  percent: null
})

// Form submition
const btnLoading = ref(false)
const isDone = ref(false)
const submitForm = async () => {
  if (btnLoading.value || isDone.value) return

  const isFormValid = schemaMaker({
    percent: modalNumber('اعتباردهی', 1)
  })

  const { percent } = formItems

  const errors = validateForms({ percent }, isFormValid)

  if (errors && errors.length > 0) {
    for (let error of errors) {
      const message = {
        type: 'error',
        message: error
      }
      store.dispatch('addToast', message)
    }
    return
  }

  btnLoading.value = true
  try {
    await addPlan(formItems)
    isDone.value = true
    btnLoading.value = false

    // Reset plans of the sell
    store.dispatch('plan/setSellPlans', [])
    // Show the message
    store.dispatch('addToast', {
      type: 'success',
      message: 'طرح اعتباری با موفقیت ایجاد گردید.'
    })
    store.dispatch('fetchHomeData')
    setTimeout(() => router.push({ name: 'PlanHome' }), 2000)
  } catch (ex) {
    if (ex.response) {
      switch (ex.response.status) {
        case 402:
          store.dispatch('profile/handleCreditModal', {
            status: 'open',
            type: 'credit'
          })
          break
        case 409:
          store.dispatch('addToast', {
            type: 'error',
            message: 'این طرح اعتباری از قبل موجود است.'
          })
          break

        default:
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
      }
    }
  } finally {
    btnLoading.value = false
  }
}

const { percent } = toRefs(formItems)
</script>

<template>
  <form novalidate class="form-wrapper" @submit.prevent="submitForm">
    <div class="credit-buttons">
      <a class="button button--form" @click="modals['percent'] = true">
        <span class="button__text active" v-show="!!percent">%</span>
        <span :class="['button__text', !!percent && 'active']">
          {{ percent ? percent : 'اعتباردهی' }}
        </span>
      </a>
      <button type="submit" class="button button--black">
        <span class="button__text">ایجاد طرح</span>
        <spinner v-if="btnLoading" />
      </button>
    </div>
    <modal
      type="center"
      title="اعتباردهی"
      :is-open="modals['percent']"
      @modalClosed="modals['percent'] = false"
    >
      <ul class="items-wrapper">
        <li v-for="item in items" :key="item">
          <a
            :class="{
              active: percent === item
            }"
            @click="
              () => {
                percent = item
                modals['percent'] = false
              }
            "
          >
            {{ item }}%
          </a>
        </li>
      </ul>
    </modal>
  </form>
</template>

<style lang="scss" scoped>
.form-wrapper {
  min-height: calc(100vh - 368px);
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
}

// change in common css
.credit-buttons {
  margin: 30px 0 0;
  flex-direction: column;
  justify-content: flex-start;
}
.button--form {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  margin: 0 0 30px;
}
.button--form .button__text {
  margin: 0;
}
</style>
