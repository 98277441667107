<script setup>
import { computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import EmptyPart from '@common/EmptyPart'
import PlanSingle from './subComponents/PlanSingle'

const store = useStore()
const plans = computed(() => store.state.plan.items)
const pageStatus = computed(() => store.state.plan.pageStatus)
store.dispatch('plan/fetchItems', { part: 'plan' })

// Handle intersection
const intersected = () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('plan/fetchItems', { part: 'plan' })
}

// Enter inside plans
const router = useRouter()
const enterPlan = (id) => {
  router.push({ name: 'PlanShow', params: { id } })
}

// Clean the room
onBeforeUnmount(() => store.dispatch('plan/clearItems'))
</script>

<template>
  <section class="list list--credit">
    <template v-for="plan in plans" :key="plan['id']">
      <plan-single
        v-if="plan['const_percent'] !== 0"
        :is-dark="plan['status'] !== '1'"
        :percent="plan['const_percent']"
        @click="enterPlan(plan['id'])"
      />
    </template>
  </section>
  <empty-part
    desc-top="طرح اعتباری"
    desc-bottom="طرح اعتباری"
    v-if="!(pageStatus == 'loading') && plans.length === 1"
  />
  <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
  <loader v-if="pageStatus == 'loading'" />
</template>

<style lang="scss" scoped>
.list--credit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 0 10px;
  margin-bottom: 20px;
}
</style>
